$primary-color: rgb(90,100,119, 0.8),
$complementary-color: rgb(251,171,53),
$complementary-transparent: rgba(251,171,53, 0.2)
$background-color: rgba(90, 100, 119, 0.1)
$sec-complementary-color: #F1ECE7,
$secondary-color: rgb(205,23,25),
$danger-color: rgb(237,79,50),
$warning-color: rgb(255,169,0),
$boder-default: 1px solid $primary-color,
$default-box-shadow: 1px 1px 0.5px 1px #888888
$default-font: 'Courier New, Courier, monospace'
