@use "../../utils" as *
@import "../../utils/variables"
  
.layout 
  height: 100vh,


.subLayout
  margin-left: 25vw,
  height: 100vh


.image 
  width: 85vw
  height: 100vh
  transition: 0.3s

.background
  height: 100vh

.alertModal
  padding: 1rem

.buttonsAlertModal
  display: flex,
  flex-direction: row,
  width: 50%
  justify-content: space-around,
  margin-top: 2rem,
  margin-bottom: 1rem