@mixin mobile() 
  @media (max-width: 600px) 
      @content

@mixin smallScreen() 
  @media (max-width: 900px) 
      @content

@mixin tablet() 
  @media (max-width: 1200px) 
      @content

