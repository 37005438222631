@use "../utils/" as *
@import "../utils/variables"

.navBar
  background-color: $primary-color

  img
    padding: 1rem 2rem

.nav-items
  margin-top: 10vh
  list-style: none,
  flex-direction: row,
  justify-content: space-evenly
  padding: 10px 10px 10px 32px
  text-decoration: none
  font-size: 25px
  color: #818181
  display: block
  transition: 0.3s

  div
    text-align: -webkit-center
    padding-top: 1rem

    span
      color: $complementary-color

  a
    text-decoration: none,
    color: white,
    font-weight: bold,
    font-size: 1.2rem

    &:hover, &:active
      color: $complementary-color,

.list
  display: flex
  flex-direction: row
  justify-content: space-between
  margin-top: 1vh

  &:hover, &:active
    color: $complementary-color,
