@use "../utils/" as *
@import "../utils/variables"

.line
    height: 2px,
    border-width: 0,
    color: $complementary-color,
    background-color: $complementary-color,

.grid-col
    display: flex,
    flex-direction: column

.techProposalForm
    display: flex,
    flex-direction: column,
    padding: 2rem 1rem,
    overflow-x: hidden,
    @include mobile()
        padding: 2rem 0.5rem

.simulatorPage
    display: flex,
    flex-direction: column,
    padding: 2rem 1rem
    overflow-x: hidden
    @include mobile()
        padding: 0

.grid
    display: flex
    flex-direction: row
    place-content: center
    align-content: center
    height: 85vh
    margin-top: 1rem

@include tablet()
    .grid
        flex-direction: column

.subGrid
    display: flex,
    flex-direction: column,
    width: 100%

.balancePage
    width: 100%
    display: flex,
    flex-direction: column,
    align-items: center

.box
    border: 1px solid $primary-color
    padding: 2% 5%
    color: $primary-color
    border-radius: 10px
    margin-bottom: 1rem
    font-weight: bold,

.subheader
    width: 100%,
    display: flex,
    flex-direction: row,
    justify-content: flex-start

.button
    background: $complementary-color
    color: $primary-color
    border: 1px solid $primary-color
    padding: 1%
    border-radius: 5px
    margin: 1rem 2rem,
    cursor: pointer
    box-shadow: 1px 1px 2px 0px $primary-color
    font-weight: bold

    &:hover,&:active
        background: $primary-color
        color: $sec-complementary-color

.mainButton
    background: $complementary-color
    color: $primary-color
    border: 1px solid $primary-color
    padding: 10%
    border-radius: 5px
    margin: 2rem 2rem,
    cursor: pointer
    box-shadow: 1px 1px 2px 0px $primary-color
    font-weight: bold

    &:hover,&:active
        background: $primary-color
        color: $sec-complementary-color

.title
    color: $primary-color,
    font-weight: bold,
    margin-bottom: 1rem,
    font-size: 2rem
    align-self: center

.buttonProp
    padding: 0 5rem
    display: flex,
    flex-direction: row,
    justify-content: space-between,
    margin-bottom: 1rem
    margin-top: 2rem

    @include mobile()
        padding: 0

.center
    text-align: -webkit-center

.pageContent
    padding: 3%

.loginForm
    display: flex,
    flex-direction: column
    padding-top: 25vh,
    padding-left: 70vh

    @include mobile()
        padding-top: 20vh,
        padding-left: 2vh
        padding-right: 2vh

.infoForm
    padding: 1rem,
    height: 70vh,
    width: 80vw
    @include mobile()
        width: 75vw

.dataTableClient
    width: 80vw,
    height: 70vh
    place-self: center
    @include tablet()
        width: 40vw
    @include smallScreen()
        width: 50vw
    @include mobile()
        width: 72vw

.dataTable
    width: 60vw,
    place-self: center
    @include tablet()
        width: 70vw
    @include smallScreen()
        width: 80vw
    @include mobile()
        width: 80vw

.pullingTable
    width: 50vw,
    place-self: center,
    margin-top: 1rem,
    @include tablet()
        width: 70vw,
        margin-top: 5vh
    @include smallScreen()
        width: 80vw
    @include mobile()
        width: 80vw

.cellClass
    cursor: pointer,
    width: 100%,
    text-align: center,
    &:hover, &:active
        background-color: $background-color,

.backdrop
    position: fixed,
    top: 0,
    left: 0,
    z-index: 100,
    width: 100vw,
    height: 100vh,
    background: rgba(0, 0, 0, 0.5),
    transition: opacity 0.2s linear,
    animation: 1s hide,
